import React, { useEffect, useState, useRef } from "react";
import LocalStorage from "../../utils/localStorage";
import $ from 'jquery';

function InfoBox() {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [formattedDate, setFormattedDate] = useState('');
    const [showModal, setShowModal] = useState(false);

    let formElement = document.querySelector('.modal__content form.form-input-set');
    let productSku = formElement ? formElement.dataset.productSku : null;

    let debitorId = LocalStorage.getDebitorId();

    const containerRef = useRef(null);

    const handleModalMouseEnter = () => {
        if (containerRef.current) {
            containerRef.current.classList.add('no-underline');
        }
    };

    const handleModalMouseLeave = () => {
        if (containerRef.current) {
            containerRef.current.classList.remove('no-underline');
        }
    };

    useEffect(() => {
        if (!LocalStorage.checkIfIsLoggedIn()) {
            return null;
        }

        const apiUrl = '/rest/V1/orders/recently/' + debitorId + '/' + productSku;

        $.ajax({
            url: apiUrl,
            type: 'GET',
            success: function(data) {
                setData(data);
                setLoading(false);

                if (data[0] && data[0][0] && data[0][0].order_date) {
                    setFormattedDate(formatDate(data[0][0].order_date));
                }
            },
            error: function(error) {
                console.error('Couldn\'t fetch data. Error:', error);
                setError(error);
                setLoading(false);
            }
        });
    }, []);

    const handleOpenModal = () => {
        setShowModal(true);
    };

    const handleCloseModal = (event) => {
        event.stopPropagation();
        setShowModal(false);
        if (containerRef.current) {
            containerRef.current.classList.remove('no-underline');
        }
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = date.getDate();
        const month = date.getMonth() + 1; // getMonth() is zero-based
        const year = date.getFullYear();

        return `${day}.${month}.${year}`;
    };

    return (
        <div className={'infobox__container'} ref={containerRef}
             onClick={handleOpenModal}
             style={data.length > 0 && !loading ? {} : { display: 'none' }}>
            <span className={'reyhericon-recent'}>
                <span className="path1"></span>
                <span className="path2"></span>
                <span className="path3"></span>
                <span className="path4"></span>
                <span className="path5"></span>
                <span className="path6"></span>
                <span className="path7"></span>
                <span className="path8"></span>
                <span className="path9"></span>
                <span className="path10"></span>
                <span className="path11"></span>
                <span className="path12"></span>
                <span className="path13"></span>
                <span className="path14"></span>
                <span className="path15"></span>
                <span className="path16"></span>
            </span>
            <div>Zuletzt bestellt am: {formattedDate}</div>

            {showModal && (
                <div className="infobox__modal"
                     onMouseEnter={handleModalMouseEnter}
                     onMouseLeave={handleModalMouseLeave}>
                    <span className={'infobox__close'} onClick={(event) => handleCloseModal(event)}></span>
                    <h3>Bestellhistorie</h3>
                    <table className={'table'}>
                        <thead>
                        <tr>
                            <th>Kaufdatum</th>
                            <th>Referenznummer</th>
                            <th>Menge</th>
                            <th>Benutzer</th>
                        </tr>
                        </thead>
                        <tbody>
                        {data[0].map((item, index) => (
                            <tr key={index}>
                                <td>{formatDate(item.order_date)}</td>
                                <td>{item.order_id}</td>
                                <td>{item.order_qty}</td>
                                <td>{item.order_customer}</td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            )}
        </div>
    );
}

export default InfoBox;